import React, { Component } from 'react';
import logo from '../../logo.svg';
import codeImage1 from '../../images/annie-spratt-QckxruozjRg-unsplash.jpg';
import codeImage2 from '../../images/mikhail-fesenko-p6YWrjhmjhM-unsplash.jpg';
import './index.css';
import {
  Image,
  Segment,
  Container,
  Grid,
  Header,
  Message,
  Divider
} from 'semantic-ui-react';

export default class Home extends Component {
  render() {
    return (
      <div>
        <div className="Home">
          <Image src={logo} className="App-logo" alt="logo" centered />
        </div>
        <div className="lander">
          <Segment basic padded='very' textAlign='center'>
            <Message positive>
              <Message.Header>Thank you for your patience while we are setting up camp</Message.Header>
              <p>
                Our launch date is quickly approaching!
              </p>
            </Message>
          </Segment>
          <Segment basic padded='very' textAlign='left' vertical>
            <Container>
              <Grid verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Header as='h1'>We Help Companies and Companions</Header>
                    <p>We can give your company superpowers to do things that you never thought possible.
                      Let us delight your customers and empower your needs... through leveraging technology.</p>
                  </Grid.Column>
                  <Grid.Column floated='right' width={6}>
                    <Image src={codeImage1} />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Header as='h1'>Who we are</Header>
                    <p>We are a leading software and technology consulting company, dedicated to delivering
                      innovative and effective solutions to businesses of all sizes. Our team of experts has
                      a deep understanding of the latest technology trends and tools, and we use this knowledge
                      to help our clients stay ahead of the competition.</p>
                    <Image src={codeImage2} size='medium' floated='left' bordered />
                    <p>We believe that technology should be accessible and easy to use, and we strive to
                      create software and systems that are simple, efficient, and reliable. Our focus is
                      always on delivering results that meet our clients' unique needs and goals, and we
                      work closely with each client to understand their business and create tailored solutions.</p>
                    <p>Whether you need help with software development, technology integration, digital
                      transformation, or any other aspect of your business, our team is here to help. We
                      have extensive experience in a wide range of industries, and we use this expertise
                      to create solutions that are both innovative and practical.</p>
                    <p>Our commitment to quality is evident in every project we undertake, and we take great
                      pride in delivering solutions that meet the highest standards of performance, security,
                      and reliability. Our goal is always to exceed our clients' expectations, and we work
                      tirelessly to ensure that each project is completed on time, within budget, and to the
                      highest standards of quality.</p>
                    <p>If you're looking for a trusted partner to help you navigate the rapidly changing world
                      of technology, look no further than our software and technology consulting company. With
                      a team of experts, a focus on results, and a commitment to quality, we're here to help
                      you succeed.</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </div>
      </div>
    );
  }
}
